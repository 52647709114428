import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import TermsContent from "./TermsContent"

type Props = {
  classes: any
}

type State = {
  checked: boolean
}

class HarassmentTerms extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      checked: false,
    }
  }

  _handleChange = (name: string) => (event: any) => {
    this.setState({
      [name]: event.target.checked,
    })
  }

  _handleClick = () => {
    const url = "https://forms.gle/JYNnZS4qtrq7J9Fp9"
    window.open(url, "_blank", "noopener,noreferrer")
  }

  render() {
    const { classes } = this.props

    return (
      <section id="assistant-service-terms" className={classes.root}>
        <div className={classes.componentWrapper}>
          <p className={classes.subTitle}>
            ここから、みんなの相談の内容を教えてください
          </p>
          <hr className={classes.border} />
          <ul>
            <li>利用規約に同意後、入力フォームへの記入が可能になります。</li>
            <li>
              匿名での相談も可能ですが、その場合、その後の対応に限界があることにご留意ください。
            </li>
            <li>
              当報告書・被害を受けた可能性がある人・加害者の可能性がある人・及びハラスメント行為発生の報告者の氏名が同意なしに公表されることはありません。
            </li>
          </ul>
          <TermsContent />
          <div className={classes.checkbox}>
            <p className={classes.support}>
              上記の利用規約の全ての項目に同意し、本相談窓口を利用される方は、
              <br />
              同意するボタンをクリックしてください。
            </p>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.checked}
                  onChange={this._handleChange("checked")}
                />
              }
              label="同意する"
            />
          </div>
          <div style={{ marginBottom: 30, marginTop: 30 }}>
            <Button
              disabled={!this.state.checked}
              className={classes.button}
              variant="contained"
              color="secondary"
              fullWidth={true}
              onClick={this._handleClick}
            >
              <p className={classes.radioText}>
                <span
                  className={classes.dataRuby}
                  //data-ruby="そうだんにゅうりょく"
                >
                  相談入力フォームへ進む
                </span>
              </p>
            </Button>
          </div>
        </div>
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    paddingTop: 20,
    marginBottom: 100,
    fontFamily:
      '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
  componentWrapper: {
    margin: "0 auto",
    maxWidth: 1280,
    paddingTop: 0,
    paddingLeft: 40,
    paddingRight: 40,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  subTitle: {
    marginTop: 30,
    fontSize: 23,
    fontWeight: "bold",
    marginBottom: 14,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      marginTop: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      marginTop: 0,
    },
  },
  border: {
    size: 2,
    backgroundColor: "white",
    height: 5,
  },
  support: {
    paddingTop: 10,
    paddingBottom: 10,
    margin: 5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
  checkbox: {
    borderTop: "solid 1px #eee",
    textAlign: "center",
    backgroundColor: "white",
    paddingTop: 15,
    paddingBottom: 15,
    marginBottom: 40,
  },
  button: {
    fontSize: 26,
    fontWeight: "bold",
    backgroundColor: "#c12f2f",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  radioText: {
    marginBottom: 0,
  },
  dataRuby: {
    position: "relative",
    "&:before": {
      content: "attr(data-ruby)",
      position: "absolute",
      top: "-1em",
      left: "-2em",
      right: "-2em",
      margin: "auto",
      fontSize: "0.5em",
    },
  },
})

export default withStyles(styles)(HarassmentTerms)
