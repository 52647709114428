import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"

const noImage = "/assets/images/noImage.jpg"

type Props = {
  classes: any
}

class AssistantServiceAdviser extends Component<Props> {
  render() {
    const { classes } = this.props

    const ballIcon = (
      <svg
        className={classes.ballIcon}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 496 512"
      >
        <path d="M483.8 179.4C449.8 74.6 352.6 8 248.1 8c-25.4 0-51.2 3.9-76.7 12.2C41.2 62.5-30.1 202.4 12.2 332.6 46.2 437.4 143.4 504 247.9 504c25.4 0 51.2-3.9 76.7-12.2 130.2-42.3 201.5-182.2 159.2-312.4zm-74.5 193.7l-52.2 6.4-43.7-60.9 24.4-75.2 71.1-22.1 38.9 36.4c-.2 30.7-7.4 61.1-21.7 89.2-4.7 9.3-10.7 17.8-16.8 26.2zm0-235.4l-10.4 53.1-70.7 22-64.2-46.5V92.5l47.4-26.2c39.2 13 73.4 38 97.9 71.4zM184.9 66.4L232 92.5v73.8l-64.2 46.5-70.6-22-10.1-52.5c24.3-33.4 57.9-58.6 97.8-71.9zM139 379.5L85.9 373c-14.4-20.1-37.3-59.6-37.8-115.3l39-36.4 71.1 22.2 24.3 74.3-43.5 61.7zm48.2 67l-22.4-48.1 43.6-61.7H287l44.3 61.7-22.4 48.1c-6.2 1.8-57.6 20.4-121.7 0z" />
      </svg>
    )

    return (
      <section className={classes.root}>
        <div className={classes.componentWrapper}>
          <div id="assitant-service-members">
            <div className={classes.flexBoxBetweenRow}>
              <p className={classes.largeSubTitle}>
                わたしたちが、みんなの相談を聞きます！
                <span className={classes.smallCaution}>
                  ※（相談選手の指名は出来ません)
                </span>
              </p>
            </div>
            <hr className={classes.redBorder} />
            <div className={classes.flexBoxCenter}>
              <div className={classes.playerInfo}>
                <p
                  className={classes.squareImg}
                  style={{
                    backgroundImage:
                      "url(" + "/assets/images/adviser_kumagai.jpg" + ")",
                  }}
                />
                <p className={classes.playerName}>熊谷紗希</p>
              </div>
              <div className={classes.playerInfo}>
                <p
                  className={classes.squareImg}
                  style={{
                    backgroundImage:
                      "url(" + "/assets/images/adviser_kinga.jpg" + ")",
                  }}
                />
                <p className={classes.playerName}>近賀ゆかり</p>
              </div>
              <div className={classes.playerInfo}>
                <p
                  className={classes.squareImg}
                  style={{
                    backgroundImage:
                      "url(" + "/assets/images/adviser_otaki.jpg" + ")",
                  }}
                />
                <p className={classes.playerName}>大滝麻未</p>
              </div>
              <div className={classes.playerInfo}>
                <p
                  className={classes.squareImg}
                  style={{
                    backgroundImage:
                      "url(" + "/assets/images/adviser_yamada.jpg" + ")",
                  }}
                />
                <p className={classes.playerName}>山田頌子</p>
              </div>
            </div>
          </div>
          <div id="assistant-service-supporters" className="pb40">
            <p className={classes.largeSubTitle}>
              弁護士と相談の専門家も、みんなをサポートしてくれます！
            </p>
            <hr className={classes.redBorder} />
            <div>
              <div className={classes.section}>【弁護士】</div>
              <div className={classes.flexBoxAround}>
                <div
                  className={classNames(
                    classes.flexBoxAround2,
                    classes.width100
                  )}
                >
                  <p
                    className={classNames(classes.playerInfo, classes.support)}
                  >
                    <span className={classes.company}>［関谷法律事務所］</span>
                    <br />
                    関谷綾子弁護士
                  </p>
                  {
                    //<p
                    //  className={classes.roundImg}
                    //  style={{ backgroundImage: "url(" + noImage + ")" }}
                    ///>
                  }
                </div>
                <div
                  className={classNames(
                    classes.flexBoxAround2,
                    classes.width100
                  )}
                >
                  <p
                    className={classNames(classes.playerInfo, classes.support)}
                  >
                    <span className={classes.company}>
                      ［弁護士法人みやこ法律事務所］
                    </span>
                    <br />
                    小林聖子弁護士
                  </p>
                  {
                    //<p
                    //  className={classes.roundImg}
                    //  style={{ backgroundImage: "url(" + noImage + ")" }}
                    ///>
                  }
                </div>
              </div>
            </div>
            <div className="pt60">
              <div className={classes.section}>【相談の専門家】</div>
              <div className={classes.flexBoxAround}>
                <div
                  className={classNames(
                    classes.flexBoxAround2,
                    classes.width100
                  )}
                >
                  <p
                    className={classNames(classes.playerInfo, classes.support)}
                  >
                    <span className={classes.company}>
                      ［認定NPO法人3keys］
                    </span>
                    <br />
                    代表理事 森山誉恵
                  </p>
                  {
                    //<p
                    //  className={classes.roundImg}
                    //  style={{ backgroundImage: "url(" + noImage + ")" }}
                    ///>
                  }
                </div>
                <div
                  className={classNames(
                    classes.flexBoxAround2,
                    classes.width100
                  )}
                >
                  <p
                    className={classNames(classes.playerInfo, classes.support)}
                  >
                    <span className={classes.company}>
                      ［ストップイットジャパン株式会社］
                    </span>
                    <br />
                    CEO 谷山大三郎
                  </p>
                  {
                    //<p
                    //  className={classes.roundImg}
                    //  style={{ backgroundImage: "url(" + noImage + ")" }}
                    ///>
                  }
                </div>
              </div>
              {
                //<div className={classNames(classes.section, "mt60")}>
                //    【相談の専門家】
                //</div>
                //<div className={classes.flexBoxAround}>
                //  <p className={classNames(classes.playerInfo, classes.support)}>
                //      認定NPO法人3keys
                //    <br />
                //    代表理事 森山誉恵
                //  </p>
                //  <p className={classNames(classes.playerInfo, classes.support)}>
                //      ストップイットジャパン株式会社
                //    <br />
                //    CEO 谷山大三郎
                //  </p>
                //</div>
              }
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    marginBottom: 40,
    fontFamily:
      '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
  },
  componentWrapper: {
    margin: "0 auto",
    maxWidth: 1280,
    paddingTop: 0,
    paddingLeft: 40,
    paddingRight: 40,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  flexBoxBetweenRow: {
    marginTop: 20,
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
  },
  largeSubTitle: {
    fontSize: 28,
    fontWeight: "bold",
    marginBottom: 14,
    paddingTop: 60,
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
      paddingTop: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 22,
    },
  },
  border: {
    size: 2,
    backgroundColor: "white",
    height: 5,
  },
  redBorder: {
    size: 2,
    backgroundColor: "#F06064",
    height: 5,
  },
  flexBoxCenter: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "center",
  },
  squareImg: {
    width: 210,
    height: 340,
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      width: 130,
      height: 210,
    },
    [theme.breakpoints.down("xs")]: {
      width: 60,
      height: 104,
    },
  },
  roundImg: {
    borderRadius: "50%",
    width: "200px",
    height: "200px",
    marginBottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      width: "130px",
      height: "130px",
      minWidth: "130px",
      minHeight: "130px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100px",
      height: "100px",
      minWidth: "100px",
      minHeight: "100px",
    },
  },
  playerInfo: {
    textAlign: "center",
  },
  playerName: {
    fontSize: 20,
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      marginTop: 15,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      marginTop: 7,
    },
  },
  flexBoxAround: {
    marginTop: 20,
    display: "flex",
    justifyContent: "space-around",
    alignContent: "center",
    alignItems: "flex-end",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "space-around",
      marginTop: 5,
    },
  },
  flexBoxAround2: {
    marginTop: 20,
    display: "flex",
    justifyContent: "space-around",
    alignContent: "center",
    alignItems: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      marginTop: 8,
      alignContent: "left",
      alignItems: "center",
    },
  },
  width100: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  support: {
    margin: 5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
      textAlign: "left",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 17,
    },
  },
  smallCaution: {
    color: "red",
    fontSize: 15,
    whiteSpace: "pre",
    paddingLeft: 10,
  },
  section: {
    marginBottom: 10,
    fontSize: 20,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  company: {
    fontWeight: "bold",
  },
})

export default withStyles(styles)(AssistantServiceAdviser)
