import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import CautionIcon from "@material-ui/icons/NewReleases"

type Props = {
  classes: any
}

class AssistantServiceWhoWhat extends Component<Props> {
  render() {
    const { classes } = this.props

    const ballIcon = (
      <svg
        className={classes.ballIcon}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 496 512"
      >
        <path d="M483.8 179.4C449.8 74.6 352.6 8 248.1 8c-25.4 0-51.2 3.9-76.7 12.2C41.2 62.5-30.1 202.4 12.2 332.6 46.2 437.4 143.4 504 247.9 504c25.4 0 51.2-3.9 76.7-12.2 130.2-42.3 201.5-182.2 159.2-312.4zm-74.5 193.7l-52.2 6.4-43.7-60.9 24.4-75.2 71.1-22.1 38.9 36.4c-.2 30.7-7.4 61.1-21.7 89.2-4.7 9.3-10.7 17.8-16.8 26.2zm0-235.4l-10.4 53.1-70.7 22-64.2-46.5V92.5l47.4-26.2c39.2 13 73.4 38 97.9 71.4zM184.9 66.4L232 92.5v73.8l-64.2 46.5-70.6-22-10.1-52.5c24.3-33.4 57.9-58.6 97.8-71.9zM139 379.5L85.9 373c-14.4-20.1-37.3-59.6-37.8-115.3l39-36.4 71.1 22.2 24.3 74.3-43.5 61.7zm48.2 67l-22.4-48.1 43.6-61.7H287l44.3 61.7-22.4 48.1c-6.2 1.8-57.6 20.4-121.7 0z" />
      </svg>
    )

    return (
      <section id="assistant-service-who-what" className={classes.root}>
        <div className={classNames(classes.componentWrapper)}>
          <div className="mt60">
            <p className={classes.subTitle}>だれが、なにを相談できる？？</p>
            <hr className={classes.border} />
            <div className={classes.section}>【だれが】</div>
            <table className={classes.table}>
              <tr>
                <th className={classes.tableTh}>{ballIcon}</th>
                <td className={classes.tableTd}>
                  女子サッカーチーム（クラブ/部活動）に所属する全ての女の子
                </td>
              </tr>
              <tr>
                <th className={classes.tableTh}>{ballIcon}</th>
                <td className={classes.tableTd}>
                  女子サッカーの育成に関わる全ての人（指導者・クラブスタッフ・ご家族）
                </td>
              </tr>
            </table>
            <div className={classes.section}>【なにを】</div>
            <table className={classes.table}>
              <tr>
                <th className={classes.tableTh}>{ballIcon}</th>
                <td className={classes.tableTd}>
                  サッカーの活動中に起こった問題（セクハラ・暴力・言葉の暴力・いじめ・嫌がらせ）
                </td>
              </tr>
              <tr>
                <th className={classes.tableTh}>{ballIcon}</th>
                <td className={classes.tableTd}>
                  女の子特有の身体の問題（生理など）
                </td>
              </tr>
              <tr>
                <th className={classes.tableTh}>{ballIcon}</th>
                <td className={classes.tableTd}>
                  その他、サッカー活動に影響するような行為
                </td>
              </tr>
            </table>
            <div className={classes.flexBoxStart}>
              <CautionIcon
                fontSize="large"
                className={classNames(classes.caution, "mr10")}
              />
              <p className={classes.caution}>
                この相談窓口では、サッカーの技術的な指導や、サッカーの活動中以外で起こったことに関する相談は受け付けておりません
              </p>
            </div>
          </div>
          <div className="pt20">
            <p className={classes.subTitle}>私たちの想い</p>
            <hr className={classes.border} />
            <table className={classes.table}>
              <tr>
                <th className={classes.tableTh}>{ballIcon}</th>
                <td className={classes.tableTd}>
                  みんなが困ったときに、安心して相談できる場所を作りたい
                </td>
              </tr>
              <tr>
                <th className={classes.tableTh}>{ballIcon}</th>
                <td className={classes.tableTd}>
                  サッカー活動を通じて辛い経験をしたり、トラウマを抱える女の子を一人でも減らしたい
                </td>
              </tr>
              <tr>
                <th className={classes.tableTh}>{ballIcon}</th>
                <td className={classes.tableTd}>
                  セクハラをはじめとする、女子サッカーを取り巻く「ハラスメント問題」を無くしたい
                </td>
              </tr>
              <tr>
                <th className={classes.tableTh}>{ballIcon}</th>
                <td className={classes.tableTd}>
                  大好きなサッカーに心配なく取り組める環境づくりに貢献したい
                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    marginBottom: 40,
    fontFamily:
      '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
  componentWrapper: {
    margin: "0 auto",
    maxWidth: 1280,
    paddingTop: 0,
    paddingLeft: 40,
    paddingRight: 40,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  subTitle: {
    marginTop: 50,
    fontSize: 23,
    fontWeight: "bold",
    marginBottom: 14,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      marginTop: 30,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      marginTop: 20,
    },
  },
  border: {
    size: 2,
    backgroundColor: "white",
    height: 5,
  },
  section: {
    marginBottom: 10,
    fontSize: 20,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  table: {
    border: "none",
    fontSize: 20,
    marginLeft: 30,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 15,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  ballIcon: {
    height: 25,
    width: 25,
    fill: "#1e365c",
    [theme.breakpoints.down("sm")]: {
      height: 30,
    },
    [theme.breakpoints.down("xs")]: {
      height: 20,
    },
  },
  tableTh: {
    border: "none",
    paddingLeft: 0,
    padding: 5,
  },
  tableTd: {
    border: "none",
    height: "10px",
    padding: 5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
    },
  },
  caution: {
    color: "red",
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
    },
  },
  flexBoxStart: {
    marginTop: 20,
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    alignItems: "center",
  },
})

export default withStyles(styles)(AssistantServiceWhoWhat)
