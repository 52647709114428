import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/assistant-service/Hero"
import Terms from "../components/assistant-service/Terms"
import WhoWhat from "../components/assistant-service/WhoWhat"
import Adviser from "../components/assistant-service/Adviser"
import ExampleExperience from "../components/assistant-service/ExampleExperience"

type Props = {
  requestProcess: Function
  completeProcess: Function
  isProcessing: boolean
  data: any
  wpModel: any
  classes: any
}

class AssistantServicePage extends Component<Props> {
  render() {
    return (
      <Layout className="pt20">
        <SEO title="オンラインお悩み相談室" />
        <Hero />
        <ExampleExperience />
        <WhoWhat />
        <Adviser />
        <Terms />
      </Layout>
    )
  }
}

export default AssistantServicePage
