import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import LazyLoad from "react-lazyload"
import InfoIcon from "@material-ui/icons/Info"
import { Info } from "@material-ui/icons"

type Props = {
  classes: any
}

class ExampleExperience extends Component<Props> {
  render() {
    const { classes } = this.props

    const ballIcon = (
      <svg
        className={classes.ballIcon}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 496 512"
      >
        <path d="M483.8 179.4C449.8 74.6 352.6 8 248.1 8c-25.4 0-51.2 3.9-76.7 12.2C41.2 62.5-30.1 202.4 12.2 332.6 46.2 437.4 143.4 504 247.9 504c25.4 0 51.2-3.9 76.7-12.2 130.2-42.3 201.5-182.2 159.2-312.4zm-74.5 193.7l-52.2 6.4-43.7-60.9 24.4-75.2 71.1-22.1 38.9 36.4c-.2 30.7-7.4 61.1-21.7 89.2-4.7 9.3-10.7 17.8-16.8 26.2zm0-235.4l-10.4 53.1-70.7 22-64.2-46.5V92.5l47.4-26.2c39.2 13 73.4 38 97.9 71.4zM184.9 66.4L232 92.5v73.8l-64.2 46.5-70.6-22-10.1-52.5c24.3-33.4 57.9-58.6 97.8-71.9zM139 379.5L85.9 373c-14.4-20.1-37.3-59.6-37.8-115.3l39-36.4 71.1 22.2 24.3 74.3-43.5 61.7zm48.2 67l-22.4-48.1 43.6-61.7H287l44.3 61.7-22.4 48.1c-6.2 1.8-57.6 20.4-121.7 0z" />
      </svg>
    )

    return (
      <section id="exmaple-experience-harasment" className={classes.root}>
        <div className={classNames(classes.componentWrapper)}>
          <div className={classes.subTitle}>こんな経験ありませんか・・・？</div>
          <hr className={classes.border} />
          <div className={classes.flexOuter}>
            <div>
              <div className={classes.fukidashiFlex}>
                <div className={classes.oneArea}>
                  <div className={classNames(classes.fukidashiDown)}>
                    コーチが性的な冗談を言うんだけど、
                    <br />
                    なんだか気分が良くないな・・・
                  </div>
                </div>
                <div className={classes.oneArea}>
                  <div
                    className={classNames(
                      classes.fukidashiDown,
                      classes.fikidashiMl,
                      classes.mt50
                    )}
                  >
                    最近、指導の時コーチによく身体を
                    <br />
                    触られるのが嫌だな・・・
                  </div>
                </div>
              </div>
              <div className={classes.fukidashiFlex}>
                <div className={classes.oneArea}>
                  <div className={classNames(classes.fukidashiDown)}>
                    同性のチームメイトを好きになった気がする。。
                    <br />
                    これって変？
                  </div>
                  <div className={classes.oneArea}>
                    <div
                      className={classNames(
                        classes.fukidashiDown,
                        classes.fikidashiMl
                      )}
                    >
                      生理が来るとサッカーに集中できない。
                      <br />
                      どうしたらいいんだろう・・・
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.fukidashiFlex}>
                <div className={classes.oneArea}>
                  <div className={classNames(classes.fukidashiTop)}>
                    チームメイトからいじめられている・・・
                    <br />
                    馴染めないのは私のせいなのかな
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.image}>
              <LazyLoad offset={200} once>
                <img
                  src="/assets/images/question-woman.png"
                  alt="こんな経験ありませんか？"
                />
              </LazyLoad>
            </div>
          </div>
          <div className={classes.fukidashiList}>
            <div className={classes.image}>
              <LazyLoad offset={200} once>
                <img
                  src="/assets/images/question-woman.png"
                  alt="こんな経験ありませんか？"
                />
              </LazyLoad>
            </div>
            <table className={classes.table}>
              <tr>
                <th className={classes.tableTh}>{ballIcon}</th>
                <td className={classes.tableTd}>
                  コーチが性的な冗談を言うんだけど、なんだか気分が良くないな・・・
                </td>
              </tr>
              <tr>
                <th className={classes.tableTh}>{ballIcon}</th>
                <td className={classes.tableTd}>
                  最近、指導の時コーチによく身体を触られるのが嫌だな・・・
                </td>
              </tr>
              <tr>
                <th className={classes.tableTh}>{ballIcon}</th>
                <td className={classes.tableTd}>
                  同性のチームメイトを好きになった気がする。。これって変？
                </td>
              </tr>
              <tr>
                <th className={classes.tableTh}>{ballIcon}</th>
                <td className={classes.tableTd}>
                  生理が来るとサッカーに集中できない。どうしたらいいんだろう・・・
                </td>
              </tr>
              <tr>
                <th className={classes.tableTh}>{ballIcon}</th>
                <td className={classes.tableTd}>
                  チームメイトからいじめられている・・・馴染めないのは私のせいなのかな
                </td>
              </tr>
            </table>
          </div>
          <div className={classes.info}>
            <InfoIcon className="mr12" />
            <div>
              もし、一つでも思い当たることがあれば、下の詳細を見てみてね！
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    marginBottom: 40,
    fontFamily:
      '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
  componentWrapper: {
    margin: "0 auto",
    maxWidth: 1280,
    paddingTop: 0,
    paddingLeft: 40,
    paddingRight: 40,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  subTitle: {
    marginTop: 50,
    fontSize: 23,
    fontWeight: "bold",
    marginBottom: 14,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      marginTop: 30,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      marginTop: 20,
    },
  },
  border: {
    size: 2,
    backgroundColor: "white",
    height: 5,
    //display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  flexOuter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "17px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  fukidashiFlex: {
    display: "flex",
    marginTop: 40,
    marginRight: 15,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  oneArea: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80px",
  },
  fukidashiDown: {
    position: "relative",
    borderRadius: "10px",
    backgroundColor: "white",
    padding: "20px",
    color: "#1e365c",
    "&:before": {
      content: '""',
      position: "absolute",
      display: "block",
      width: 0,
      height: 0,
      borderRadius: "50%",
      transform: "rotate(45deg)",
      right: "-15px",
      top: "22px",
      borderLeft: "25px solid transparent",
      borderTop: "25px solid #ffffff",
      borderRight: "25px solid transparent",
      borderBottom: "25px solid transparent",
    },
    "&:after": {
      content: '""',
      position: "absolute",
      display: "block",
      width: 0,
      height: 0,
      borderRadius: "50%",
      transform: "rotate(45deg)",
      right: "-25px",
      top: "40px",
      borderLeft: "25px solid transparent",
      borderTop: "25px solid #f8d4d8",
      borderRight: "25px solid transparent",
      borderBottom: "25px solid transparent",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  fukidashiTop: {
    position: "relative",
    borderRadius: "10px",
    backgroundColor: "white",
    padding: "20px",
    color: "#1e365c",
    "&:before": {
      content: '""',
      position: "absolute",
      display: "block",
      width: 0,
      height: 0,
      borderRadius: "50%",
      transform: "rotate(135deg)",
      right: "-15px",
      top: "22px",
      borderLeft: "25px solid transparent",
      borderTop: "25px solid #ffffff",
      borderRight: "25px solid transparent",
      borderBottom: "25px solid transparent",
      [theme.breakpoints.down("sm")]: {
        top: "0px",
      },
    },
    "&:after": {
      content: '""',
      position: "absolute",
      display: "block",
      width: 0,
      height: 0,
      borderRadius: "50%",
      transform: "rotate(135deg)",
      right: "-25px",
      top: "5px",
      borderLeft: "25px solid transparent",
      borderTop: "25px solid #f8d4d8",
      borderRight: "25px solid transparent",
      borderBottom: "25px solid transparent",
      [theme.breakpoints.down("sm")]: {
        top: "-15px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  fikidashiMl: {
    marginLeft: 80,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 30,
    },
  },
  mt40: {
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
  image: {
    width: 180,
    marginTop: 40,
    marginRight: "auto",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      width: 100,
    },
  },
  fukidashiList: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "inline",
    },
  },
  table: {
    border: "none",
    fontSize: 20,
    marginLeft: 30,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 15,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  ballIcon: {
    height: 25,
    width: 25,
    fill: "#1e365c",
    [theme.breakpoints.down("sm")]: {
      height: 30,
    },
    [theme.breakpoints.down("xs")]: {
      height: 20,
    },
  },
  tableTh: {
    border: "none",
    paddingLeft: 0,
    padding: 5,
  },
  tableTd: {
    border: "none",
    height: "10px",
    padding: 5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
    },
  },
  info: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4),
    },
  },
})

export default withStyles(styles)(ExampleExperience)
