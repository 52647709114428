import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import LazyLoad from "react-lazyload"

type Props = {
  classes: any
}

class AssistantServiceHero extends Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <section id="assistant-service-hero" className={classes.root}>
        <div className={classes.mainTitle}>「オンラインお悩み相談室」</div>
        <div
          className={classNames(classes.componentWrapper, classes.explanation)}
        >
          <div className={classes.image}>
            <LazyLoad offset={200} once>
              <img src="/assets/images/web5.jpg" alt="オンラインお悩み相談室" />
            </LazyLoad>
          </div>
          <div className={classes.message}>
            <div>
              私たちなでケアは、「みんなが不安を抱えることなく、心配なく大好きなサッカーを思い切り楽しめる環境づくりに貢献したい」という想いのもと、女子サッカーに関するお悩み相談室を設置しました！
            </div>
            <div>
              現役・引退を含む女子サッカー選手が一人ひとりのお悩みに寄り添っていきます！
              <br />
              悩みを一人で抱え込まず、まずはなでケアの選手に相談してみませんか？
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    paddingTop: 40,
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },
  },
  mainTitle: {
    fontFamily:
      '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    marginTop: 50,
    marginBottom: 50,
    fontSize: 40,
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 36,
      marginBottom: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 22,
      marginBottom: 0,
    },
  },
  componentWrapper: {
    margin: "0 auto",
    maxWidth: 1280,
    paddingTop: 0,
  },
  explanation: {
    textAlign: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingTop: 30,
  },
  image: {
    width: 500,
    marginRight: "auto",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  message: {
    fontFamily:
      '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    fontSize: 20,
    width: "50%",
    textAlign: "left",
    lineHeight: 2,
    letterSpacing: 1.6,
    overflow: "hidden",
    marginRight: "auto",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: 17,
      lineHeight: 1.6,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
})

export default withStyles(styles)(AssistantServiceHero)
